<template>
  <div class="formdata" style="padding: 20px">
    <el-form label-width="160px">
      <h3>
        处方信息<template v-if="prescriptionInfo.deliveryState === 'deleted'">
          -{{ deliveryStateDIC[prescriptionInfo.deliveryState] }}
        </template>
      </h3>
      <div class="prescriptionInfo-border">
        <div class="circulaation-style">
          <el-form-item label="医疗机构信息:"> XX健康互联网医院 </el-form-item>
          <el-form-item label="处方订单号:">
            {{ prescriptionInfo.prescriptionId }}
          </el-form-item>
          <el-form-item label="开方医师:">
            {{ prescriptionInfo.doctorName }}
          </el-form-item>
        </div>
        <div class="circulaation-style">
          <el-form-item label="审方药师:">
            {{ prescriptionInfo.auditBy }}
          </el-form-item>
          <el-form-item label="患者姓名:">
            {{ prescriptionInfo.patientName }}
          </el-form-item>
          <el-form-item label="订单支付时间:">
            {{ prescriptionInfo.payTime }}
          </el-form-item>
        </div>
        <div class="circulaation-style">
          <el-form-item label="手机号码:">
            {{ prescriptionInfo.patientMobile }}
          </el-form-item>
          <el-form-item label="药品明细:">
            <!-- <span
            >
            {{ drug}} -->
            <!-- {{drug}} -->
            <span>{{ drug }}</span>
            <template v-if="isShowSymbol">
              <el-tooltip
                :content="prescriptionInfo.medicalName"
                placement="top-start"
                effect="light"
              >
                <span>...</span>
              </el-tooltip>
            </template>

            <!-- </span> -->
          </el-form-item>
          <template v-if="prescriptionInfo.deliveryState === 'deleted'">
            <el-form-item label="审核不通过原因:">
              {{ prescriptionInfo.failReason }}
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item />
          </template>
        </div>
      </div>
      <template v-if="prescriptionInfo.deliveryState !== 'deleted'">
        <h3>流转信息-{{ deliveryStateDIC[prescriptionInfo.deliveryState] }}</h3>
        <div class="prescriptionInfo-border">
          <div class="circulaation-style">
            <el-form-item label="售药药店:">
              {{ prescriptionInfo.drugstoreName }}
            </el-form-item>
            <el-form-item label="售药方式:">
              {{ saleMethodDic[prescriptionInfo.saleMethod] }}
            </el-form-item>
            <el-form-item label="处方确认人姓名:">
              {{ prescriptionInfo.confirmerName }}
            </el-form-item>
          </div>
          <div class="circulaation-style">
            <el-form-item label="处方确认时间:">
              {{ prescriptionInfo.confirmTime }}
            </el-form-item>
            <el-form-item />
            <el-form-item />
            <!-- <el-form-item
            label="处方配送执行人姓名:"
          >
            {{ form }}
          </el-form-item>
          <el-form-item
            label="处方配送执行人姓名:"
          >
            {{ form }}
          </el-form-item> -->
          </div>
        </div>
        <div v-for="(item, index) in logisticsInfoVOList" :key="item.id">
          <h3>物流{{ index + 1 }}</h3>
          <div class="commodityStatus">
            <div class="circulaation-style">
              <el-form-item label="配送机构名称:">
                {{ item.deliveryOrgName }}
              </el-form-item>
              <el-form-item label="配送操作人:">
                {{ item.deliveryMan }}
              </el-form-item>
              <el-form-item label="配送操作人联系电话:">
                {{ item.deliveryManTel }}
              </el-form-item>
            </div>
            <div class="circulaation-style">
              <el-form-item label="物流单号:">
                {{ item.deliveryOrderNo }}
              </el-form-item>
              <el-form-item label="配送时间:">
                {{ item.deliveryTime }}
              </el-form-item>
              <el-form-item label="配送状态:">
                {{ deliveryStatusDic[item.deliveryState] }}
              </el-form-item>
            </div>
            <div class="circulaation-style">
              <el-form-item label="是否已到达:">
                {{ item.deliveryState === 'COMPLETED' ? '是' : '否' }}
              </el-form-item>
              <el-form-item label="配送到达时间:">
                {{ item.completeTime }}
              </el-form-item>
              <el-form-item label="是否及时:">
                {{ item.onTime ? '是' : '否' }}
              </el-form-item>
            </div>
            <div class="circulaation-style">
              <el-form-item label="是否出错:">
                {{ item.errorFlag ? '是' : '否' }}
              </el-form-item>
              <el-form-item label="处方配送执行人姓名:">
                {{ item.deliveryManName }}
              </el-form-item>
              <el-form-item label="处方配送订单执行时间:">
                {{ item.deliveryOrderTime }}
              </el-form-item>
            </div>
            <div class="circulaation-style">
              <el-form-item label="出错原因:">
                {{ item.errorReason ? item.errorReason : '无' }}
              </el-form-item>
              <el-form-item />
              <el-form-item />
            </div>
          </div>
        </div>
      </template>

      <el-form-item>
        <el-button type="primary" @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deliveryStateDIC } from '@/utils/component/publicList';
export default {
  name: 'Details',
  data() {
    this.saleMethodDic = {
      1: '到店自取',
      2: '选择配送',
    };
    this.deliveryStatusDic = {
      WAIT_DELIVERE: '待配送',
      DELIVERING: '配送中',
      COMPLETED: '已到达',
      DELETED: '已作废',
    };
    return {
      deliveryStateDIC: deliveryStateDIC, // 配送状态字典
      logisticsInfoVOList: [],
      drug: '', // 展示药品第一个明细
      isShowSymbol: false, // 是否在药品后面显示省略号
    };
  },
  computed: {
    prescriptionInfo() {
      console.log('computed');
      return JSON.parse(this.$route.query.detail) || {};
    },
  },
  watch: {},
  mounted() {
    console.log('mounted');

    this.getLogisticsDetail();
  },
  methods: {
    ...mapActions('basicDictionary', ['consultationTypeEdit']),
    // 物流信息详情
    getLogisticsDetail() {
      const params = {
        mallOrderNo: JSON.parse(this.$route.query.detail).mallOrderNo,
      };
      this.$api.distributionDetail(params).then((res) => {
        this.logisticsInfoVOList = res.data.logisticsInfoVOList;
      });
      const medicalNameList = this.prescriptionInfo.medicalName.split(',');
      this.drug = medicalNameList[0];
      if (medicalNameList.length > 1) {
        this.isShowSymbol = true;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.circulaation-style {
  display: flex;
  flex-direction: row;
}
.el-form-item--small.el-form-item {
  flex: 1;
}
.commodityStatus,
.prescriptionInfo-border {
  border: 1px solid #909399;
  margin: 10px 0px;
}

.drug {
  display: inline-block;
  width: 38%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
